import image1 from '../components/Quiz/question_1.jpg';
import image2 from '../components/Quiz/question_2.jpg';
import image3 from '../components/Quiz/question_3.jpg';
import image4 from '../components/Quiz/question_4.jpg';
import image5 from '../components/Quiz/question_5.jpg';
import image6 from '../components/Quiz/question_6.jpg';
import image7 from '../components/Quiz/question_7.jpg';
import image8 from '../components/Quiz/question_8.jpg';
import image9 from '../components/Quiz/question_9.jpg';
import image10 from '../components/Quiz/question_10.jpg';
import image11 from '../components/Quiz/question_11.jpg';
import image12 from '../components/Quiz/question_12.jpg';

const questions = [
  {
    id: 1,
    type: "single-choice",
    theme: "news",
    text: "В 2024 году был установлен мировой рекорд по пребыванию в космосе. Его автор:",
    options: ["Олег Кононенко", "Геннадий Падалка", "Марк Ванде Хай", "Иван Вагнер"],
    correctAnswer: 0,
    image: image1,
    sourceimage: "Фото: ТАСС",
    modalText: `Командир отряда космонавтов Олег Кононенко провел в космосе 1111 суток.\nПрошлый рекорд также поставил российский космонавт Геннадий Падалка, который за 5 миссий с 1998 по 2015 годы налетал в сумме 878 суток`
  },
  {
    id: 2,
    type: "single-choice",
    theme: "news",
    text: "В 2024 году Россия стала центром притяжения молодежи со всего мира, развеяв миф об изоляции страны. Где прошло мероприятие?",
    options: ["Казань", "Сириус", "Москва", "Санкт-Петербург"],
    correctAnswer: 1,
    image: image2,
    sourceimage: "Фото: vk.com",
    modalText: `Всемирный фестиваль молодёжи в Сириусе собрал 20 тысяч молодых лидеров из 190 стран мира. Фестиваль, и еще ряд крупнейших международных мероприятий, которые прошли в России, в очередной раз подчеркнули, что Россия не находится в изоляции и является центром создания многополярного мира`
  },
  {
    id: 3,
    type: "single-choice",
    theme: "news",
    text: "Какое событие в 2024 году объединило больше половины россиян и стало главным событием года?",
    options: ["Выборы Президента РФ", "Саммит БРИКС", "Новый кабмин России", "Изменение ядерной доктрины"],
    correctAnswer: 0,
    image: image3,
    sourceimage: "Фото: РИА Новости",
    modalText: `На выборах Президента России в 2024 году было установлено сразу 3 исторических рекорда – Владимира Путина поддержали более 87% проголосовавших (больше половины населения страны), явка на выборы составила более 77%, а показатель доверия к результатам выборов превысил 83%`
  },
  {
    id: 4,
    type: "single-choice",
    theme: "svo",
    text: "Кустарник, который стал настоящим мемом после 21 ноября 2024 года",
    options: ["Ольха", "Орешник", "Липа", "Акация"],
    correctAnswer: 1,
    image: image4,
    sourceimage: "Фото: соцсети",
    modalText: `Россия 21 ноября впервые применила на СВО новейшую гиперзвуковую баллистическую ракету «Орешник», которая способна поражать любые цели на территории Европы. Максимальная сила удара – 45 Хиросим. \nВладимир Путин предложил Западу высокотехнологичную дуэль, заявив, что ракету невозможно перехватить`
  },
  {
    id: 5,
    type: "single-choice",
    theme: "svo",
    text: "Какой вид боевой техники, ставшей главной особенностью СВО, был описан в советской фантастике еще в 1968 году?",
    options: ["Боевая ступа", "Кукурузник", "Дрон", "Лазерный пистолет"],
    correctAnswer: 2,
    image: image5,
    sourceimage: "Фото: Мастерская новых медиа",
    modalText: `Впервые о войне дронов написал в своем романе-антиутопии «Час Быка» советский фантаст Иван Ефремов.\nПрименение дронов стало главной отличительной особенностью СВО. Только в 2024 году Россия увеличила выпуск БПЛА почти в 10 раз`
  },
  {
    id: 6,
    type: "single-choice",
    theme: "svo",
    text: "Какая российская разработка покорила жителей Китая в 2024 году?",
    options: ["«Орлан»", "Superjet 100", "Ту-214", "Истребитель Су-57"],
    correctAnswer: 3,
    image: image6,
    sourceimage: "Фото: ПАО «ОАК»",
    modalText: `В 2024 году на международном авиасалоне в Чжухае представили экспортную модель истребителя пятого поколения Су-57 – самый современный боевой самолет России. Его «нарушающий законы физики» полет произвел настоящий фурор, а западные обозреватели отметили, что летная программа Су-57 заставила замолчать критиков машины`
  },
  {
    id: 7,
    type: "single-choice",
    theme: "culture",
    text: "«Факт — самая упрямая в мире вещь». Из какой самой продаваемой цифровой книги 2024 года цитата?",
    options: ["Гарри Поттер", "Вий", "Мастер и Маргарита", "Евгений Онегин"],
    correctAnswer: 2,
    image: image7,
    sourceimage: "Фото: РИА Новости",
    modalText: `Фраза принадлежит Воланду из романа «Мастер и Маргарита». В 2024 году это произведение Михаила Булгакова стало самой продаваемой художественной книгой по версии одного из крупнейших книжных цифровых сервисов`
  },
  {
    id: 8,
    type: "single-choice",
    theme: "culture",
    text: "Какая ценность является самой главной в жизни по мнению подавляющего большинства россиян?",
    options: ["Путешествия", "Образование", "Семья", "Коллективизм"],
    correctAnswer: 2,
    image: image8,
    sourceimage: "Фото: семья Манойленко",
    modalText: `По данным ВЦИОМ, 87% россиян считают, что одной из важнейших целей для человека является создание семьи, 90% россиян уверены, что в семье должны быть дети. В 2025 году стартует нацпроект «Семья», который усилит поддержку семей с детьми и будущих родителей`
  },
  {
    id: 9,
    type: "single-choice",
    theme: "culture",
    text: "Какое произведение российского писателя в 2024 году стало хитом тиктокеров по всему миру?",
    options: ["«Белые Ночи»", "«Капитанская дочка»", "«Война и Мир»", "«Колобок»"],
    correctAnswer: 0,
    image: image9,
    sourceimage: "Фото: соцсеть TikTok",
    modalText: `В соцсетях, посвященных обсуждению книг – «буктоке» и «букстаграме» – пользователи по всему миру в течение 2024 года признавались в любви повести Федора Достоевского «Белые ночи». Эта история показывает, что наше культурное наследие – сила, которая способна зажечь сердца сквозь государственные, цифровые и языковые барьеры`
  },
  {
    id: 10,
    type: "single-choice",
    theme: "sport",
    text: "Российские школьники на первой олимпиаде первые в мире. О какой олимпиаде 2024 года идет речь?",
    options: ["По биохакингу", "По 3D-технологиям", "Сварка по металлу", "Олимпиада по ИИ"],
    correctAnswer: 3,
    image: image10,
    sourceimage: "Фото: Telegram-канал Минцифры России",
    modalText: `Российская сборная в 2024 году выиграла первую Международную олимпиаду по ИИ, оставив позади 39 стран. Лучший результат российские старшеклассники показали, используя навыки программирования, знание библиотек Python и понимание основ машинного обучения`
  },
  {
    id: 11,
    type: "single-choice",
    theme: "sport",
    text: "Кто из российских тренеров в первом же матче привел китайскую футбольную команду к победе в Суперкубке страны?",
    options: ["Станислав Черчесов", "Леонид Слуцкий", "Валерий Карпин", "Юрий Семин"],
    correctAnswer: 1,
    image: image11,
    sourceimage: "Фото: Shanghai Shenhua",
    modalText: `С января 2024 года футбольный клуб «Шанхай Шэньхуа» возглавил российский тренер Леонид Слуцкий. В феврале в первом же матче под его руководством китайский клуб выиграл Суперкубок страны. Его задача: возвращать команду в число топов китайского чемпионата`
  },
  {
    id: 12,
    type: "single-choice",
    theme: "sport",
    text: "Чемпионат мира по плаванию на короткой воде 2024 года стал успешным для сборной России. Сколько медалей завоевал 18-летний пловец Мирон Лифанцев?",
    options: ["1", "2", "5", "7"],
    correctAnswer: 2,
    image: image12,
    sourceimage: "Фото: Федерация водных видов спорта России",
    modalText: `Впервые с 2021 года российские спортсмены отправились полноценной командой на чемпионат мира по плаванию на короткой воде. 18-летний Мирон Лифинцев завоевал сразу 5 золотых медалей, что считается выдающимся достижением для спортсмена такого юного возраста`
  }]

  ///


export default questions;
