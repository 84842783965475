import React from "react";
import "./StartScreen.scss";

const StartScreen = ({ onStart }) => {
  return (
    <div className="start-screen">
      
      <h2 className="start-screen__lead">
      Ответь на вопросы из разных сфер жизни за 2024 год и узнай, какой герой нашего времени близок именно тебе!
      </h2>

      <h1 className="start-screen__title">
        Герои нашего времени
      </h1>

      {/* <svg width="333" height="130" viewBox="0 0 333 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 56.7V0H15.714L38.394 37.017H30.132L52.164 0H67.878L68.04 56.7H50.625L50.463 26.244H53.217L38.151 51.597H29.727L14.013 26.244H17.415V56.7H0Z" fill="white"/>
      <path d="M95.4901 21.06H120.762V34.83H95.4901V21.06ZM96.7861 42.282H125.136V56.7H77.9941V0H124.083V14.418H96.7861V42.282Z" fill="white"/>
      <path d="M170.232 48.6V14.823H157.191L157.029 18.873C156.921 21.843 156.732 24.624 156.462 27.216C156.192 29.808 155.814 32.184 155.328 34.344C154.896 36.504 154.329 38.421 153.627 40.095C152.925 41.715 152.061 43.065 151.035 44.145L130.866 41.877C132.648 41.877 134.106 41.283 135.24 40.095C136.428 38.853 137.373 37.152 138.075 34.992C138.777 32.778 139.29 30.186 139.614 27.216C139.992 24.192 140.262 20.871 140.424 17.253L141.072 0H189.024V48.6H170.232ZM129.246 67.797V41.877H196.476V67.797H178.98V56.7H146.742V67.797H129.246Z" fill="white"/>
      <path d="M202.896 56.7V0H221.688V29.484L241.209 0H259.191V56.7H240.399V27.297L220.959 56.7H202.896Z" fill="white"/>
      <path d="M264.483 56.7L289.269 0H308.061L332.847 56.7H313.083L294.777 9.072H302.229L283.923 56.7H264.483ZM279.225 46.818L284.085 33.048H310.167L315.027 46.818H279.225Z" fill="white"/>
      <path d="M0 128.7V72H43.173V86.823H14.661L18.792 82.692V128.7H0Z" fill="white"/>
      <path d="M67.0136 93.06H92.2856V106.83H67.0136V93.06ZM68.3096 114.282H96.6596V128.7H49.5176V72H95.6066V86.418H68.3096V114.282Z" fill="white"/>
      <path d="M104.414 128.7V72H131.711C137.003 72 141.566 72.864 145.4 74.592C149.288 76.32 152.285 78.804 154.391 82.044C156.497 85.23 157.55 89.01 157.55 93.384C157.55 97.758 156.497 101.538 154.391 104.724C152.285 107.91 149.288 110.394 145.4 112.176C141.566 113.904 137.003 114.768 131.711 114.768H115.025L123.53 106.587V128.7H104.414ZM123.53 108.693L115.025 100.026H130.496C133.142 100.026 135.086 99.432 136.328 98.244C137.624 97.056 138.272 95.436 138.272 93.384C138.272 91.332 137.624 89.712 136.328 88.524C135.086 87.336 133.142 86.742 130.496 86.742H115.025L123.53 78.075V108.693Z" fill="white"/>
      <path d="M192.972 129.996C188.382 129.996 184.116 129.267 180.174 127.809C176.286 126.351 172.911 124.299 170.049 121.653C167.187 118.953 164.946 115.794 163.326 112.176C161.76 108.558 160.977 104.616 160.977 100.35C160.977 96.03 161.76 92.088 163.326 88.524C164.946 84.906 167.187 81.774 170.049 79.128C172.911 76.428 176.286 74.349 180.174 72.891C184.116 71.433 188.382 70.704 192.972 70.704C197.616 70.704 201.882 71.433 205.77 72.891C209.658 74.349 213.033 76.428 215.895 79.128C218.757 81.774 220.971 84.906 222.537 88.524C224.157 92.088 224.967 96.03 224.967 100.35C224.967 104.616 224.157 108.558 222.537 112.176C220.971 115.794 218.757 118.953 215.895 121.653C213.033 124.299 209.658 126.351 205.77 127.809C201.882 129.267 197.616 129.996 192.972 129.996ZM192.972 114.525C194.754 114.525 196.401 114.201 197.913 113.553C199.479 112.905 200.829 111.987 201.963 110.799C203.151 109.557 204.069 108.072 204.717 106.344C205.365 104.562 205.689 102.564 205.689 100.35C205.689 98.136 205.365 96.165 204.717 94.437C204.069 92.655 203.151 91.17 201.963 89.982C200.829 88.74 199.479 87.795 197.913 87.147C196.401 86.499 194.754 86.175 192.972 86.175C191.19 86.175 189.516 86.499 187.95 87.147C186.438 87.795 185.088 88.74 183.9 89.982C182.766 91.17 181.875 92.655 181.227 94.437C180.579 96.165 180.255 98.136 180.255 100.35C180.255 102.564 180.579 104.562 181.227 106.344C181.875 108.072 182.766 109.557 183.9 110.799C185.088 111.987 186.438 112.905 187.95 113.553C189.516 114.201 191.19 114.525 192.972 114.525Z" fill="white"/>
      <path d="M231.847 128.7V72H250.639V101.484L270.16 72H288.142V128.7H269.35V99.297L249.91 128.7H231.847Z" fill="white"/>
      <path d="M262.38 70.7C254.591 70.7 249.061 69.5062 245.788 67.1186C242.581 64.6768 240.405 61.2039 240.405 56.7L259.189 56.7C259.189 57.7619 259.174 58.566 259.978 59.2C260.463 59.5824 261.192 59.8747 261.978 59.7C262.404 59.6053 262.622 59.453 262.978 59.2C263.906 58.5404 263.978 57.7 264.478 56.7H283.978C281.978 62.2 280.316 65.2582 276.978 67.7C273.706 70.0876 270.168 70.7 262.38 70.7Z" fill="#11CCCC"/>
      </svg> */}

      
      <div className="btn_Wrapper"><button className="start-screen__button" onClick={onStart}>
        Узнать&nbsp;&nbsp;{"  "}
        {/* <svg
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="40.5"
            width="40"
            height="40"
            rx="20"
            transform="rotate(90 40.5 0)"
            fill="#11CCCC"
          />
          <path
            d="M11.4774 20.0019L29.5225 20.0019M29.5225 20.0019L22.3044 27.2199M29.5225 20.0019L22.3044 12.7838"
            stroke="#E0EFFF"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> */}
        {/* <span className="start-screen__icon">➔</span> */}
      </button>
      </div>
    </div>
  );
};

export default StartScreen;
