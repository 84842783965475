import React, { useState } from "react";
import ProgressBar from "./ProgressBar";
import QuestionWrapper from "./QuestionWrapper";
import StartScreen from "./StartScreen";
import ResultScreen from "./ResultScreen";
import questions from "../../utils/questionData";
import "./Quiz.scss";
import bgVideo from "./bg.mp4"; // Импорт видео

// import * as THREE from 'three';

const Quiz = ({sessionId, lp}) => {
  const [currentScreen, setCurrentScreen] = useState("start"); // Экран: start, quiz, result
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  
  // main();
  // console.log('START')
  // var guid = () => {
  //   var w = () => { 
  //     return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); 
  //   }
  //   return  `${w()}${w()}-${w()}-${w()}-${w()}-${w()}${w()}${w()}`;
  // }
  // const url_to_poster = guid + '.png'
  // localStorage.setItem('url_to_poster', url_to_poster.value);
  
  // function main() {
  //   const canvas = document.querySelector('#c');
  //   const renderer = new THREE.WebGLRenderer({canvas});
  //   renderer.autoClearColor = false;
  
  //   const camera = new THREE.OrthographicCamera(
  //     -1, // left
  //      1, // right
  //      1, // top
  //     -1, // bottom
  //     -1, // near,
  //      1, // far
  //   );
  //   const scene = new THREE.Scene();
  //   const plane = new THREE.PlaneGeometry(2, 2);
  
  //   const fragmentShader = `
  //   #include <common>
  
  //   uniform vec3 iResolution;
  //   uniform float iTime;
  
  //   void mainImage( out vec4 fragColor, in vec2 fragCoord ) {
  //     // Normalized pixel coordinates (from 0 to 1)
  //     vec2 uv = ( fragCoord - .5*iResolution.xy ) / iResolution.y;

  //     float red;
  //   float green;
  //   float blue;
    
  //   float dist;
  //   vec2 dif;
    
  //   float blur;
    
  //   float x;
  //   float y;
    
  //   float offset;

  //     // Random number generator
  //   float random(float x) {
  //     return fract(sin(x * 12.9898) * 43758.5453123);
  //   }

    
  //   //general
    
  //   blur = 0.25; //  0.005 + (sin(iTime*0.3)+1.0)/10.0;
  //   offset = sin(iTime*0.3) * 0.4;
    
  //   //green circle
    
  //   x = sin(radians(0.0) + iTime / 3.0) * offset;
  //   y = cos(radians(0.0) + iTime / 3.0) * offset;
  //   x = random(radians(0.0) + iTime / 3.0) * offset
    
  //   dif = vec2(x-uv.x, y-uv.y);
  //   dist = length (dif);
    
  //   green = smoothstep(0.3,0.3-blur,dist);
      
  //     // Create a time-varying gradient between two colors
  //     vec3 bgCol = vec3(86.0/255.0, green, 234.0/255.0); // #5629EA
  //     vec3 col1 = vec3(17.0/255.0, 204.0/255.0, 204.0/255.0); // #11CCCC
  //     vec3 col1m = mix(bgCol, col1, 0.5);
  //     vec3 col2 = vec3(68.0/255.0, 25.0/255.0, 163.0/255.0); // #4419A3
      
  //     vec3 col = mix(col1m, col2, sin(iTime * 0.5)); // Mix based on sine wave
      
  //     // Apply the background color
      
  //     fragColor = vec4(mix(bgCol, col, smoothstep(0.4, 0.6, length(uv))), 1.0);
  //   }

  
  //   void main() {
  //     mainImage(gl_FragColor, gl_FragCoord.xy);
  //   }
  //   `;
  //   const uniforms = {
  //     iTime: { value: 0 },
  //     iResolution:  { value: new THREE.Vector3() },
  //   };
  //   const material = new THREE.ShaderMaterial({
  //     fragmentShader,
  //     uniforms,
  //   });
  //   scene.add(new THREE.Mesh(plane, material));
  
  //   function resizeRendererToDisplaySize(renderer) {
  //     const canvas = renderer.domElement;
  //     const width = canvas.clientWidth;
  //     const height = canvas.clientHeight;
  //     const needResize = canvas.width !== width || canvas.height !== height;
  //     if (needResize) {
  //       renderer.setSize(width, height, false);
  //     }
  //     return needResize;
  //   }
  
  //   function render(time) {
  //     time *= 0.001;  // convert to seconds
  
  //     resizeRendererToDisplaySize(renderer);
  
  //     const canvas = renderer.domElement;
  //     uniforms.iResolution.value.set(canvas.width, canvas.height, 1);
  //     uniforms.iTime.value = time;
  
  //     renderer.render(scene, camera);
  
  //     requestAnimationFrame(render);
  //   }
  
  //   requestAnimationFrame(render);
  // }
  
  

  const handleStart = () => {
    setCurrentScreen("quiz");
    var { retrieveLaunchParams, postEvent } = window.telegramApps.sdk;
    lp = retrieveLaunchParams();
    
    if (lp && !['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
      // alert(document.getElementsByClassName('quiz-wrapper')[0])
      document.getElementsByClassName('quiz-wrapper')[0].classList.add('mobile-quiz');
    }
  }
  const handleAnswer = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setCurrentScreen("result");
    }
    
  };

  return (
    
    <div className="quiz-wrapper">
      {/* <canvas id="c"  onStart={main()}>
        
      </canvas> */}
     
      <video
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
        src={bgVideo} // Используем импортированный файл
      ></video>
      <div className="quiz-container">
        {currentScreen === "start" && <StartScreen onStart={handleStart} />}
        {currentScreen === "quiz" && (
          <>
            <ProgressBar
              current={currentQuestionIndex + 1}
              total={questions.length}
            />
            <QuestionWrapper
              question={questions[currentQuestionIndex]}
              onAnswer={handleAnswer}
            />
          </>
        )}
        {currentScreen === "result" && (
          <ResultScreen answers={answers} questions={questions} sessionId={sessionId} lp={lp}/>
        )}
      </div>
    </div>
  );
};

export default Quiz;




