import React, { useState } from "react";
import "./SingleChoiceQuestion.scss";

const SingleChoiceQuestion = ({ question, onAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleAnswerClick = (index) => {
    if (selectedOption !== null) return; // Блокировка повторного выбора

    setSelectedOption(index);
    const correct = index === question.correctAnswer;
    setIsCorrect(correct);
    if(correct && window.TelegramWebviewProxy){
      const data = JSON.stringify({ 
        type: 'notification',
        notification_type: 'success'
      });
      window.TelegramWebviewProxy.postEvent('web_app_trigger_haptic_feedback', data);
    } else if (window.TelegramWebviewProxy){
      const data = JSON.stringify({ 
        type: 'notification',
        notification_type: 'error'
      });
      window.TelegramWebviewProxy.postEvent('web_app_trigger_haptic_feedback', data);
    }

    // Показать модальное окно спустя секунду после ответа
    setTimeout(() => {
      setShowModal(true);
    }, 1000);
  };

  const handleNextQuestion = () => {
    setShowModal(false);
    setSelectedOption(null); // Сброс состояния для нового вопроса
    setIsCorrect(null);
    onAnswer(question.id, selectedOption);
  };

  return (
    <div className="question single-choice">
      <h2 className="title">{question.text}</h2>
      <p className="text">Выберите вариант:</p>
      <div className="optionssg">
        {question.options.map((option, index) => {
          let optionClass = "optionsg";
          if (selectedOption !== null) {
            if (selectedOption === index) {
              optionClass += isCorrect ? " correct" : " incorrect";
            } else if (index === question.correctAnswer) {
              optionClass += " correct"; // Подсвечиваем правильный ответ
            } else {
              optionClass += " inactive";
            }
          }

          return (
            <div
              key={index}
              className={optionClass}
              onClick={() => handleAnswerClick(index)}
            >
              <div className="circlesg">{index + 1}</div>
              {option}
            </div>
          );
        })}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-image">
              <img src={question.image} alt="Изображение" />
            </div>
            <div className="modal-image-source">
              {question.sourceimage}
            </div>
            <p>{question.modalText}</p>
            <button className="modal-btn" onClick={handleNextQuestion}>Дальше</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleChoiceQuestion;